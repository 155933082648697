import Amgen from "images/homepage/logo01.svg";
import Argonne from "images/homepage/logo08.svg";
import Biogen from "images/homepage/logo03.svg";
import CMU from "images/homepage/logo04.svg";
import CSL from "images/homepage/logo05.svg";
import GSK from "images/homepage/logo06.svg";
import HomepageLogo from "images/homepage/svg-TrancendtheLab2.svg";
import Lilly from "images/homepage/logo02.svg";
import Lottie from "react-lottie-player";
import Merck from "images/homepage/logo07.svg";
import PlayBtn from "images/PlayBtn.png";
import PlayBtnHover from "images/PlayBtnHover.png";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Stanford from "images/homepage/logo09.svg";
import VirtualTourBackground from "images/virtual-tour.png";
import bgECLLAB1 from "images/homepage/ECL_website-BGimages-1.jpg";
import bgECLLAB2 from "images/homepage/ECL_website-BGimages-2.jpg";
import bgECLLAB3 from "images/homepage/ECL_website-BGimages-3.jpg";
import cn from "classnames";
import hexGroup1 from "images/homepage/hex-group-on-green.svg";
import hexGroupGrey from "images/homepage/hex-group-on-white.svg";
import hexOutline1 from "images/homepage/hex-outline-FFF.svg";
import hexSingle1 from "images/homepage/hex-single-on-green.svg";
import lottieAnimation from "images/homepage/lottie-bg3.json";
import pageImage from "images/HomepageLogo.png";
import scrollingArrow from "images/homepage/arrow.svg";
import styled, { keyframes } from "styled-components";
import useSize from "@react-hook/size";
import { Layout, SEO, VimeoPlayer, loadTestimonial } from "components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { device, routes } from "utils";
import { isMobile } from "react-device-detect";

const S = {};

function TrustedByCustomers(props) {
  const { isOver1024, isUnder459 } = props;
  return (
    <S.TrustedByContainer>
      <div className="text">Trusted by</div>
      <S.LogoContainer
        className={cn({ compact: !isOver1024, "under-width": isUnder459 })}
      >
        {isOver1024 && (
          <>
            <S.Logo
              src={CSL}
              alt="trusted-by-company"
              width={40}
              height={20}
              loading="lazy"
            />
            <S.Logo
              src={CMU}
              alt="trusted-by-company"
              width={60}
              height={20}
              loading="lazy"
            />
            <S.Logo
              src={Biogen}
              alt="trusted-by-company"
              width={90}
              height={30}
              loading="lazy"
            />
            <S.Logo
              src={Lilly}
              alt="trusted-by-company"
              width={50}
              height={30}
              loading="lazy"
            />
            <S.Logo
              src={GSK}
              alt="trusted-by-company"
              width={50}
              height={40}
              loading="lazy"
            />
            <S.Logo
              src={Merck}
              alt="trusted-by-company"
              width={130}
              height={40}
              loading="lazy"
            />
            <S.Logo
              src={Amgen}
              alt="trusted-by-company"
              width={80}
              height={20}
              loading="lazy"
            />
            <S.Logo
              src={Argonne}
              alt="trusted-by-company"
              width={130}
              height={40}
              loading="lazy"
            />
            <S.Logo
              src={Stanford}
              alt="trusted-by-company"
              width={120}
              height={30}
              loading="lazy"
            />
          </>
        )}
        {!isOver1024 && (
          <>
            <S.Row>
              <S.Logo
                src={Biogen}
                alt="trusted-by-company"
                width={90}
                height={30}
                loading="lazy"
              />
              <S.Logo
                src={Lilly}
                alt="trusted-by-company"
                width={50}
                height={30}
                loading="lazy"
              />
              <S.Logo
                src={GSK}
                alt="trusted-by-company"
                width={50}
                height={40}
                loading="lazy"
              />
              <S.Logo
                src={CSL}
                alt="trusted-by-company"
                width={40}
                height={20}
                loading="lazy"
              />
              <S.Logo
                src={Amgen}
                alt="trusted-by-company"
                width={80}
                height={20}
                loading="lazy"
              />
            </S.Row>
            <S.Row className="last">
              <S.Logo
                src={CMU}
                alt="trusted-by-company"
                width={60}
                height={20}
                loading="lazy"
              />
              <S.Logo
                src={Merck}
                alt="trusted-by-company"
                width={130}
                height={40}
                loading="lazy"
              />
              <S.Logo
                src={Argonne}
                alt="trusted-by-company"
                width={130}
                height={40}
                loading="lazy"
              />
              <S.Logo
                src={Stanford}
                alt="trusted-by-company"
                width={120}
                height={30}
                loading="lazy"
              />
            </S.Row>
          </>
        )}
      </S.LogoContainer>
    </S.TrustedByContainer>
  );
}

S.TrustedByContainer = styled.div`
  color: #b1bac2;
  padding: 5rem 0 10rem 0;
  & .text {
    margin-bottom: 2rem;
  }
`;

S.Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  &.last {
    margin-bottom: 5rem;
  }
`;

S.LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.compact {
    transform: scale(0.9);
    flex-direction: column;
    &.under-width {
      transform: scale(0.8);
    }
  }
`;

S.Logo = styled.img`
  margin: 0 15px;
`;

function SectionOne(props) {
  const { title, isOver1024, isUnder459 } = props;

  const videoContainerRef = useRef(null);

  const scheduleDemoUrl = "https://emeraldcloudlab.my.site.com/schedule/s";
  const vimeoVideoId = "242887434";
  const vimeoPreviewImageId =
    "1661847505-e4086aaf103a55438803bf7d5fc15b5482486c32367b219bf8451cd02dfbcaad-d";

  return (
    <>
      <S.SectionContainer ref={videoContainerRef}>
        <S.ContentContainer>
          <S.Content>
            <div>
              <S.H1>Transcend the LAB</S.H1>
              <S.LogoImg
                src={HomepageLogo}
                loading="lazy"
                alt={title}
                title={title}
              />
              <span>
                <b>Cloud labs</b> are fully software controlled, highly
                automated life science laboratories that allow scientists to
                design, execute, and analyze experiments remotely from anywhere
                on earth. These laboratories represent a significant
                technological leap, offering comprehensive control over the
                entire spectrum of wet lab procedures, spanning sample
                preparation, experimental execution, method refinement, and
                scalability. Cloud labs redefine the traditional boundaries of
                scientific research.
              </span>
            </div>
            <S.ScheduleDemo href={scheduleDemoUrl}>
              Schedule a tour &amp; demo
            </S.ScheduleDemo>
            <TrustedByCustomers
              isOver1024={isOver1024}
              isUnder459={isUnder459}
            />
          </S.Content>
          <img
            className="scroll-arrow"
            src={scrollingArrow}
            alt="scroll down indicator"
          />
        </S.ContentContainer>
        <S.BackgroundVideoContainer>
          <S.VideoContainer>
            <S.VideoPlayerStyled>
              <VimeoPlayer
                videoId={vimeoVideoId}
                observerRef={videoContainerRef}
                previewImageId={vimeoPreviewImageId}
                background
                lazy={false}
              />
            </S.VideoPlayerStyled>
          </S.VideoContainer>
        </S.BackgroundVideoContainer>
      </S.SectionContainer>
    </>
  );
}

S.SectionContainer = styled.div`
  background-color: #16191c;
  display: block;
  height: 700px;
  overflow: hidden;
  background-color: black;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
`;

S.ContentContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  -webkit-box-pack: center;
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 100%;
  height: 700px;
  z-index: 2;
  transition: background 3s ease-out;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  & .scroll-arrow {
    position: absolute;
    bottom: 45px;
    align-self: center;
    margin: auto;
  }
  &.headerBG {
    background: rgba(0, 0, 0, 0);
    opacity: 0;
  }
`;

S.Content = styled.div`
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  & span {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    max-width: 580px;
    display: inline-block;
    color: white;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  & img {
    z-index: 999;
  }
`;

// Added for SEO purposes
S.H1 = styled.h1`
  position: absolute;
  left: -9999px;
`;

S.LogoImg = styled.img`
  display: block;
  margin: auto;
  max-width: 68vw;
  transform: scale(1.1);
  margin-top: 6rem;
  @media all and ${device.sm} {
    transform: none;
    margin-bottom: 0;
  }
`;

S.ScheduleDemo = styled.a`
  background-color: transparent;
  width: 190px;
  height: 30px;
  line-height: 28px;
  border: 1px solid #b1bac2;
  border-radius: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: "Ringside Narrow";
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    transition: all 0.3s linear;
    color: #ffffff;
    border: 1px solid ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.black};
  }
`;

S.BackgroundVideoContainer = styled.figure`
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  left: 0;
  margin: 0;
  position: fixed;
  z-index: 1;
  top: -140px;
  width: 100%;
  height: 850px;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
`;

S.VideoContainer = styled.div`
  height: 200%;
  left: 0;
  position: absolute;
  top: -140px;
  width: 100%;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

S.VideoPlayerStyled = styled.div`
  z-index: 1;
  display: block;
  height: 100%;
  left: 50%;
  min-width: 3000px;
  min-height: 600px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 1;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
`;

function SectionTwoGreenContent(props) {
  const { isOver767 } = props;

  const sectionTitleLoose = (
    <h3>
      The Cloud Lab
      <br />
      accelerates R&D for
      <br />
      everyone
    </h3>
  );
  const sectionTitleCompact = (
    <h3>The Cloud Lab accelerates R&D for everyone</h3>
  );
  const sectionText = (
    <span>
      <b>ECL®</b> brings the most comprehensive life science research lab on the
      planet to your fingertips from anywhere in the world. ECL is agile and
      scalable to a variety of missions. Some use ECL to access top of the line
      equipment more easily during Discovery; others use ECL to automate and
      optimize routine and ongoing workflows such as CMC; and still others
      operate startups, conduct academic research, and teach graduate level
      classes exclusively on the cloud without a traditional lab.
    </span>
  );

  return (
    <>
      {isOver767 && (
        <div className="content">
          <div className="hex-left">
            <img src={hexOutline1} alt="one hexagonal" />
          </div>
          <div className="content-left">{sectionTitleLoose}</div>
          <div className="content-right">
            {sectionText}
            <div className="example-list">
              <span>
                <b>Example Applications</b>
              </span>
              <ul>
                <li>Pharmaceutical Research and Development</li>
                <li>Biotech Research and Development</li>
                <li>Drug Metabolism and Pharmacokinetics (DMPK)</li>
                <li>Translational Medicine</li>
                <li>Consumer Products Research and Development</li>
                <li>Chemistry, Manufacturing, and Controls (CMC)</li>
                <li>Compendial Testing and Sample Preparation</li>
                <li>Contract Research (CROs)</li>
                <li>Diagnostics Research</li>
                <li>Material Science</li>
                <li>Formulation and Stability</li>
                <li>AI Driven Laboratory Research</li>
              </ul>
            </div>
            <S.ButtonNav to={routes("whyCloudLabLanding")}>
              Learn More
            </S.ButtonNav>
          </div>
        </div>
      )}
      {!isOver767 && (
        <div className="content-767">
          {sectionTitleCompact}
          {sectionText}
          <S.ButtonNav
            to={routes("whyCloudLabLanding")}
            style={{ alignSelf: "center" }}
          >
            Learn More
          </S.ButtonNav>
        </div>
      )}
    </>
  );
}

S.ButtonNav = styled(Link)`
  border-radius: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: normal;
  white-space: nowrap;
  padding: 0.4rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.2s ease-in;
  width: auto;
  border: 1px solid #ffffff;
  margin-top: 20px;
  max-width: fit-content;

  &.active {
    background-color: ${({ theme }) => theme.black};
    color: #ffffff;
    border: 1px solid ${({ theme }) => theme.white};
  }
  &:hover {
    transition: all 0.3s linear;
    color: #31353b;
    border-color: #ffffff;
    background-color: #ffffff;
  }
`;

function SectionTwoGreen(props) {
  const { isOver767, isOver959 } = props;

  return (
    <S.SectionGreen isOver767={isOver767}>
      <div className="backdrop-header">
        <div className="backdrop">
          <div className="content-container">
            <S.IntroVideo>
              <VimeoPlayer
                videoId="665283990"
                previewImageId="1792013347-d124988a2b10e12cc936bbec84695f51ce0132277099744c9be1aee2e5456afb-d"
                title="Introduction to Emerald Cloud Lab"
                lazy={false}
              />
            </S.IntroVideo>
            <SectionTwoGreenContent isOver767={isOver767} />
          </div>
        </div>
      </div>
      {isOver767 && (
        <div className={`hex-right${isOver959 ? "" : "-959"}`}>
          <img
            src={isOver959 ? hexGroup1 : hexSingle1}
            alt="two hexagonal images"
          />
        </div>
      )}
    </S.SectionGreen>
  );
}

S.SectionGreen = styled.div`
  z-index: 99;
  position: relative;
  margin-top: -50px;
  margin-bottom: -18px;
  & .backdrop-header {
    z-index: 15;
    padding-bottom: 18px;
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
    opacity: 1;
    transition: background 1s;
    background: linear-gradient(
      to right,
      rgba(12, 188, 150, 0.7),
      rgba(0, 174, 239, 0.7)
    );
    clip-path: polygon(
      0% 0%,
      calc(50% - 50px) 0%,
      50% 28px,
      calc(50% + 50px) 0%,
      100% 0%,
      100% 100%,
      0% 100%
    );
  }
  & .backdrop {
    z-index: 15;
    padding-top: 50px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    opacity: 1;
    transition: background 1s;
    background-image: linear-gradient(to right, #0cbc96, #006f73);
    clip-path: polygon(
      0% 0%,
      calc(50% - 50px) 0%,
      50% 28px,
      calc(50% + 50px) 0%,
      100% 0%,
      100% 100%,
      0% 100%
    );
  }
  & .backdrop-header-tall {
    z-index: 15;
    padding-bottom: 18px;
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
    opacity: 1;
    transition: background 1s;
    background: linear-gradient(
      to right,
      rgba(12, 188, 150, 0.7),
      rgba(0, 174, 239, 0.7)
    );
  }

  & .backdrop-tall {
    z-index: 15;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    opacity: 1;
    transition: background 1s;
    background-image: linear-gradient(to right, #0cbc96, #006f73);
  }
  & .ontop-media-content {
    display: relative;
    z-index: 20;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 480px;
  }
  & .ontop-media-content-959 {
    display: relative;
    z-index: 20;
    margin: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    height: calc(48vh - 40px);
  }

  & .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .content-container {
    width: 850px;
    height: 100%;
    align-items: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  & .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    justify-self: center;
    justify-content: flex-start;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 30px;
    position: relative;
  }
  & .content-767 {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    align-self: center;
    justify-self: center;
    justify-content: flex-start;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 30px;
    position: relative;
  }
  & .content-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  & .content-tall {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-self: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: flex-start;
    margin: 20px 20px 20px 4rem;
    position: relative;
  }
  & .content-tall-767 {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    align-self: center;
    justify-self: center;
    justify-content: flex-start;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 30px;
    position: relative;
    & h3 {
      align-self: flex-start;
    }
  }
  & .content-left {
    display: flex;
    width: 300px;
    flex-shrink: 0;
    text-align: right;
    padding-right: 20px;
    flex-direction: column;
    align-self: flex-start;
    line-height: 1.2;
  }
  & .content-right {
    display: flex;
    flex-direction: column;
    width: 500px;
  }
  & .hex-left {
    position: absolute;
    z-index: 50;
    opacity: 0.7;
    bottom: 80px;
    left: -90px;
    & img {
      float: right;
    }
  }
  & .hex-right {
    position: absolute;
    z-index: 50;
    width: 260px;
    margin-right: 20px;
    opacity: 1;
    bottom: -32px;
    right: calc(50% - 600px);
    & img {
      float: right;
    }
  }
  & .hex-right-959 {
    position: absolute;
    z-index: 50;
    width: 260px;
    margin-right: 20px;
    opacity: 1;
    bottom: -32px;
    right: calc(50% - 380px);
    & img {
      float: right;
    }
  }
  & h3 {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  & span {
    display: inline-block;
    font-size: 14px;
    color: white;
  }
  & .example-list {
    color: white;
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
    & span {
      font-size: 14px;
    }
    & li {
      padding-left: 14px;
    }
    & li::before {
      font-size: 8px;
    }
    & ul {
      padding-left: 30px;
    }
  }
`;

S.IntroVideo = styled.div`
  z-index: 3;
  margin: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  height: 0;
  & iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

function SectionThreeWhite(props) {
  const { isOver767, sectionThreeRef } = props;

  const sectionTitle = <h3>A massive laboratory at your fingertips</h3>;
  const sectionText = (
    <span>
      <b>ECL®</b> is equipped with over 200 different instrument models remotely
      controlled by a single unified software interface, ECL Command Center®,
      empowering you to orchestrate nearly any in vitro biology or chemistry
      experiment from cradle to grave at the click of a button. The facility
      operates 24 hours a day, 365 days a year with on-demand access to
      instrumentation, samples, and data. ECL supports many research domains,
      with a particular focus on the biotechnology and pharmaceutical
      industries, and its state of the art equipment includes instrumentation
      such as HPLC, FPLC, GC, Mass Spectrometers, NMR, PCR, qPCR, ddPCR, ELISA,
      SPR/BLI, Western Blots, Flow Cytometers/FACS, and hundreds more.
    </span>
  );

  return (
    <S.SectionThreeWhite>
      <div className="content">
        {isOver767 && (
          <img
            className="hex-left"
            src={hexGroupGrey}
            alt="two hexagonal images"
          />
        )}
        <div ref={sectionThreeRef} className={`text hide`}>
          {sectionTitle}
          {sectionText}
          {isOver767 && (
            <div className="example-list">
              <span>
                <b>Example Disciplines</b>
              </span>
              <ul>
                <li>Analytical Chemistry</li>
                <li>Biochemistry</li>
                <li>Chemical Biology & Bioorganic Chemistry</li>
                <li>Cell Biology (coming soon)</li>
                <li>Physical Chemistry & Biophysical Chemistry</li>
                <li>Organic Chemistry (coming soon)</li>
                <li>Structural Biology</li>
                <li>Genetic Engineering & Synthetic Biology</li>
                <li>Synthetic Chemistry & Medicinal Chemistry (coming soon)</li>
              </ul>
            </div>
          )}
          <S.ButtonInstrument to={routes("instrumentation")}>
            See the Instrument List
          </S.ButtonInstrument>
        </div>
        {isOver767 && (
          <img
            className="hex-right"
            src={hexGroupGrey}
            alt="two hexagonal images"
          />
        )}
      </div>
    </S.SectionThreeWhite>
  );
}

S.SectionThreeWhite = styled.div`
  z-index: 10;
  padding-bottom: 55px;
  padding-top: 50px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: white;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  background-position: center center;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;

  & .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin: auto;
  }
  & .hex-right {
    opacity: 1;
    align-self: flex-start;
    margin-top: 120px;
  }
  & .hex-left {
    opacity: 1;
    color: black;
    align-self: center;
    transform: rotate(120deg);
  }
  & .text {
    margin: 0 4rem;
    max-width: 580px;
    transition: opacity 2s ease;
    &.show {
      opacity: 1;
    }
    &.hide {
      opacity: 0;
    }
  }

  & .example-list {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
    & span {
      font-size: 14px;
    }
    & li {
      padding-left: 14px;
    }
    & li::before {
      font-size: 8px;
    }
    & ul {
      padding-left: 30px;
    }
  }
  & h3 {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    margin-bottom: 14px;
  }
  & span {
    display: inline-block;
    font-size: 14px;
    color: black;
  }
`;

S.ButtonInstrument = styled(Link)`
  border-radius: 0;
  color: #2f2f2f;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: normal;
  padding: 0.4rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.2s ease-in;
  width: auto;
  border: 1px solid #31353b;
  margin-top: 20px;
  &.active {
    background-color: ${({ theme }) => theme.black};
    color: #ffffff;
    border: 1px solid ${({ theme }) => theme.white};
  }
  &:hover {
    transition: all 0.3s linear;
    color: #ffffff;
    border-color: #2f2f2f;
    background-color: #2f2f2f;
  }
  @media all and ${device.md} {
    max-width: 20rem;
  }
`;

function SectionFourCarousel() {
  return (
    <S.Carousel>
      <S.Images>
        <StaticImage
          src="../images/homepage/instrument-banner-1.png"
          layout="fixed"
          width={1960}
          height={295}
          alt="instrument carousel piece"
        />
        <StaticImage
          src="../images/homepage/instrument-banner-2.png"
          layout="fixed"
          width={1960}
          height={295}
          alt="instrument carousel piece"
        />
        <StaticImage
          src="../images/homepage/instrument-banner-3.png"
          layout="fixed"
          width={1960}
          height={295}
          alt="instrument carousel piece"
        />
        <StaticImage
          src="../images/homepage/instrument-banner-4.png"
          layout="fixed"
          width={1960}
          height={295}
          alt="instrument carousel piece"
        />
        <StaticImage
          src="../images/homepage/instrument-banner-1.png"
          layout="fixed"
          width={1960}
          height={295}
          alt="instrument carousel piece"
        />
      </S.Images>
    </S.Carousel>
  );
}

S.Carousel = styled.div`
  z-index: 3;
  height: 295px;
  position: relative;
  overflow: hidden;
`;

const rotate = keyframes`
  0% { left: 0; }
  100% { left: -7840px; }
`;

S.Images = styled.div`
  display: flex;
  top: 0;
  left: 0%;
  height: 100%;
  ${"" /* background-color: red; */}
  width: auto;
  position: absolute;
  margin-bottom: 100px;
  flex-direction: row;
  animation: ${rotate} 100s linear infinite;
`;

function SectionFiveGreenContent(props) {
  const { isOver767 } = props;

  const sectionTitleLoose = (
    <h3>
      A single Software
      <br />
      interface to the entire
      <br />
      laboratory
    </h3>
  );
  const sectionTitleCompact = (
    <h3>A single Software interface to the entire laboratory</h3>
  );
  const sectionText = (
    <span>
      <b>ECL®</b> Command Center provides a full platform for data analysis and
      visualization with a suite of over 4,500 functions, including advanced
      machine learning, image processing, and multi-dimensional analysis, and
      seamlessly integrates with our ECL Constellation® knowledge base. ECL
      Constellation automatically saves and annotates all experimental methods
      and data with complete metadata to ALCOA+ standards and verifies data
      integrity to ensure that everything is model quality. This eliminates the
      need for individual data visualization, analysis, ELN, LIMS, LES/MES, QMS
      and even ERP systems and streamlines them into a single coherent package.
    </span>
  );

  return (
    <>
      {isOver767 && (
        <div className="content-tall">
          <div className="hex-left">
            <img src={hexOutline1} alt="one hexagonal" />
          </div>
          <div className="content-left">{sectionTitleLoose}</div>
          <div className="content-right">
            {sectionText}
            <S.ButtonNav to={routes("howItWorksLanding")}>
              See How It Works
            </S.ButtonNav>
          </div>
        </div>
      )}
      {!isOver767 && (
        <div className="content-tall-767">
          {sectionTitleCompact}
          {sectionText}
          <S.ButtonNav
            to={routes("whyCloudLabLanding")}
            style={{ alignSelf: "center" }}
          >
            See How It works
          </S.ButtonNav>
        </div>
      )}
    </>
  );
}

function SectionFiveGreen(props) {
  const { isOver767, isOver959 } = props;

  return (
    <S.SectionGreen>
      <div className="backdrop-header-tall">
        <div className="backdrop-tall">
          <div className="content-container">
            <div className="player-wrapper">
              <div className="react-player">
                <VimeoPlayer
                  videoId="567692654"
                  previewImageId="1345981240-419ca27036cdf3c11f23d32165a0f13220ffb10c0569af8e62fc6cd9f4f5d830-d"
                  background
                />
              </div>
            </div>
            <SectionFiveGreenContent isOver767={isOver767} />
          </div>
        </div>
      </div>
      {isOver767 && (
        <div className={`hex-right${!isOver959 ? "-959" : ""}`}>
          <img
            src={isOver959 ? hexGroup1 : hexSingle1}
            alt="two hexagonal images"
          />
        </div>
      )}
    </S.SectionGreen>
  );
}

function SectionSixWhite(props) {
  const { isUnder459, sectionSixRef } = props;

  const sectionTitle = <h3>A comprehensive laboratory for all your needs</h3>;
  const sectionText = (
    <span>
      <b>ECL®</b> uniquely brings both automated and manual techniques to the
      cloud. Regardless of type, all techniques are scriptable and push-button
      reproducible on ECL. ECL’s groundbreaking lab execution system allows
      manual techniques considered automation unfriendly, such as stock solution
      generation, solid handling, rotovaping, speedvacing, and lyophilization,
      to be reliably automated and scripted in the same manner as automated
      liquid handlers and robotic workcells. This even includes all the
      foundational sample preparation operations (at all bench-relevant scales),
      from dosing solids to liquid handling to cell culture to reactors, used in
      almost every method.
    </span>
  );

  return (
    <S.SectionSixWhite>
      <div className="gradient-buffer">
        <div className="content-container">
          <div className="content">
            {!isUnder459 && (
              <>
                <img
                  className="hex-left"
                  src={hexGroupGrey}
                  alt="two hexagonal images"
                />
                <img
                  className="hex-right"
                  src={hexGroupGrey}
                  alt="two hexagonal images"
                />
              </>
            )}
            <div ref={sectionSixRef} className={`text hide`}>
              {sectionTitle}
              {sectionText}
            </div>
          </div>
          <VirtualTour isUnder459={isUnder459} />
          {/* <Testimonial /> */}
        </div>
      </div>
    </S.SectionSixWhite>
  );
}

S.SectionSixWhite = styled.div`
  z-index: 10;
  position: relative;

  & .content-container {
    background-color: white;
    width: 100%;
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
  }
  & .tour-wrapper {
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  & .testimonial-divider {
    width: 80%;
    max-width: 820px;
    height: 1px;
    border: 1px solid rgb(177, 186, 194);
    margin: 70px auto 20px auto;
  }
  & .testimonial-wrapper {
    display: flex;
    justify-content: center;
    & .animation-container {
      background-color: rgba(255, 255, 255, 1);
      & canvas {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
  & .gradient-buffer {
    padding-bottom: 18px;
    background: linear-gradient(
      to right,
      rgba(12, 188, 150, 0.7),
      rgba(0, 174, 239, 0.7)
    );
    overflow: hidden;
    position: relative;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin-bottom: -18px;
  }
  & .content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin: auto;
  }
  & .hex-right {
    position: absolute;
    opacity: 1;
    z-index: 1;
    align-self: flex-end;
    right: -140px;
    bottom: -172px;
  }
  & .hex-left {
    z-index: 1;
    opacity: 1;
    position: absolute;
    left: -160px;
    bottom: -300px;
    color: black;
    align-self: flex-end;
    transform: rotate(120deg);
  }
  & .text {
    align-self: center;
    margin: 20px;
    margin-top: 0;
    max-width: 580px;
    transition: opacity 2s ease;
    &.show {
      opacity: 1;
    }
    &.hide {
      opacity: 0;
    }
  }
  & h3 {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
  }
  & span {
    display: inline-block;
    font-size: 14px;
    color: black;
  }
`;

function VirtualTour(props) {
  const { isUnder459 } = props;

  const [hovered, setBtnHovered] = useState(false);
  const [isMatterportLoaded, setIsMatterportLoaded] = useState(false);

  const matterportIframeRef = useRef(null);

  const matterportContainerRef = useRef(null);
  const [matterportContainerWidth] = useSize(matterportContainerRef);

  const matterportViewerDimensions = useRef({
    width: 580,
    height: 326,
    heightToWidthRatio: 326 / 580,
  });

  useEffect(() => {
    const convertRemToPixels = (rem) =>
      rem *
      parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    matterportViewerDimensions.current = {
      width: convertRemToPixels(90),
      height: convertRemToPixels(50.7),
      heightToWidthRatio: convertRemToPixels(50.7) / convertRemToPixels(90),
    };
  }, []);

  useEffect(() => {
    const { width, heightToWidthRatio } = matterportViewerDimensions.current;
    if (matterportContainerWidth && matterportContainerWidth < width) {
      matterportViewerDimensions.current.width = matterportContainerWidth;
      matterportViewerDimensions.current.height =
        Math.floor(matterportContainerWidth * heightToWidthRatio) + 1;
    }
  }, [matterportContainerWidth, matterportViewerDimensions]);

  // MatterPort URL parameters
  // https://support.matterport.com/hc/en-us/articles/209980967-URL-Parameters
  const matterportParams = [
    "m=9AHcUP4zzDy", // Video ID
    "brand=0", // No company info in upper left
    "hl=0", // No highlight reel
    "mt=0", // Hide Mattertags and "Mattertag™ Content" toggle in the "About" panel
    "gt=0", // Hide Guided Tour buttons in bottom left corner
    "mls=2", // MLS-friendly. No About panel, no VR, no Mattertag Posts
    "hr=0", // Hide highlight reel at bottom
    "vr=0", // No VR link
    "help=0", // No Help link
    "play=1", // Start playing immediately
    "qs=0", // No QuickStart (show the 3d zoom in first)
    "dh=1", // Show Dollhouse view on initial fly-in
    "title=0", // Don't show space title ('ECL1 Lab Tour MM/DD/YYY')
  ].join("&");

  return (
    <div className="tour-wrapper">
      <S.MatterportViewer
        ref={matterportContainerRef}
        className={`${isUnder459 ? "mobile" : ""}`}
      >
        {isMatterportLoaded ? (
          <iframe
            ref={matterportIframeRef}
            title="virtual-tour"
            width={matterportViewerDimensions.current.width}
            height={matterportViewerDimensions.current.height}
            src={`https://my.matterport.com/show/?${matterportParams}`}
            allowFullScreen
            allow="xr-spatial-tracking"
            onLoad={() => matterportIframeRef.current.focus()}
          />
        ) : (
          <S.MatterportPreview
            onClick={() => setIsMatterportLoaded(true)}
            style={{
              width: `${matterportViewerDimensions.current.width}px`,
              height: `${matterportViewerDimensions.current.height}px`,
            }}
          >
            <S.PreviewImage
              src={VirtualTourBackground}
              alt="Start virtual tour"
              width={matterportViewerDimensions.current.width}
              loading="lazy"
            />
            <S.PreviewImageDimmer />
            <S.PlayControl>
              <S.PlayButton
                src={hovered ? PlayBtnHover : PlayBtn}
                onMouseEnter={() => setBtnHovered(true)}
                onMouseLeave={() => setBtnHovered(false)}
                alt="Play"
                title="Play"
                loading="lazy"
              />
              <S.PlayButtonText>START TOUR</S.PlayButtonText>
            </S.PlayControl>
          </S.MatterportPreview>
        )}
      </S.MatterportViewer>
    </div>
  );
}

S.MatterportViewer = styled.div`
  overflow: hidden;
  display: flex;
  align-self: center;
  justify-content: center;
  z-index: 2;
`;

S.MatterportPreview = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

S.PreviewImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  object-fit: fill;
`;

S.PreviewImageDimmer = styled.div`
  background-color: black;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

S.PlayControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  img {
    margin-right: 2rem;
    cursor: pointer;
    flex: 0 0 auto;
    @media all and ${device.md} {
      margin-top: 0;
    }
  }
  div {
    pointer-events: none;
    flex: 0 0 auto;
    color: white;
    font-size: 2.4rem;
    font-weight: 600;
  }
  &:hover,
  &:focus {
    div {
      text-decoration: underline;
    }
  }
`;

S.PlayButton = styled.img`
  cursor: pointer;
  max-width: 50px;
  max-height: 50px;
`;

S.PlayButtonText = styled.div`
  flex: 0 0 auto;
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 1rem;
`;

function Testimonial() {
  const [isTestimonialLoaded, setIsTestimonialLoaded] = useState(false);
  useEffect(() => {
    const onWindowLoad = () => {
      if (!isTestimonialLoaded) {
        setIsTestimonialLoaded(true);
        loadTestimonial();
      }
    };
    if (typeof createjs !== "undefined" && document.readyState === "complete") {
      setTimeout(onWindowLoad, 1000);
    }

    window.addEventListener("load", onWindowLoad);

    return () => window.removeEventListener("load", onWindowLoad);
  }, [isTestimonialLoaded]);

  return (
    <>
      <div className="testimonial-divider" />
      <div className="testimonial-wrapper">
        <div id="animation_container" className="animation-container">
          <canvas id="canvas"></canvas>
        </div>
      </div>
    </>
  );
}

function SectionSevenEnd(props) {
  const { isOver767 } = props;

  const sectionTitle = <h3>Join the cloud lab revolution</h3>;
  const sectionText = (
    <span>
      <b>ECL®</b> is ready for you. ECL can be used to run everything from
      one-off experiments on top of the line equipment to design of experiment
      style optimization to full scale-up for high throughput experimentation.
      There are a wide range of account options, from academic packages, to
      comprehensive subscriptions, to facilities for large enterprises and
      universities built and operated by ECL.
    </span>
  );

  return (
    <S.SectionSevenEnd>
      <div className={`${!isOver767 ? "content-mobile" : "content"}`}>
        <div className={`${!isOver767 ? "text-mobile" : "text show"}`}>
          {sectionTitle}
          {sectionText}
          <S.ButtonNav to={routes("getStartedLanding")}>
            Launch Your Account Today
          </S.ButtonNav>
        </div>
        {!isOver767 && (
          <div className="lottiePlayerMobile">
            <Lottie
              loop
              animationData={lottieAnimation}
              play
              style={{
                width: 700,
              }}
            />
          </div>
        )}
      </div>
      {isOver767 && (
        <div className="lottie-wrapper">
          <div className="lottiePlayer">
            <Lottie
              loop
              animationData={lottieAnimation}
              play
              style={{
                width: 1024,
                height: 683,
              }}
            />
          </div>
        </div>
      )}
    </S.SectionSevenEnd>
  );
}

S.SectionSevenEnd = styled.div`
  z-index: 10;
  padding-top: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: black;
  background-image: radial-gradient(
    at center 40%,
    rgb(39, 82, 103) 0%,
    rgb(22, 25, 28) 72%,
    rgb(22, 25, 28) 100%
  );
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  &.fifBg {
    transition: background 3s ease-in;
  }
  & .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    justify-content: center;
    margin: auto;
    width: 810px;
  }
  & .text {
    width: 640px;
    justify-self: flex-start;
    margin-bottom: -100px;
    transition: opacity 2s ease;
    &.show {
      opacity: 1;
    }
    &.hide {
      opacity: 0;
    }
  }
  & .content-mobile {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    margin: auto;
  }
  & .text-mobile {
    position: absolute;
    top: 50px;
    margin: 20px;
    margin-top: 50px;
    max-width: 640px;
    transition: opacity 2s ease;

    &.show {
      opacity: 1;
    }
    &.hide {
      opacity: 0;
    }
  }
  & .lottie-wrapper {
    z-index: -1;
    width: 60.1em;
    position: relative;
    margin: auto;
    justify-self: flex-end;
    height: 510px;
    align-self: flex-end;
  }
  & .lottiePlayer {
    z-index: -1;
    position: absolute;
    justify-self: center;
    bottom: 0px;
    right: 0px;
  }
  & .lottiePlayerMobile {
    z-index: -1;
    justify-self: flex-end;
    align-self: flex-end;
  }
  & h3 {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
  }
  & span {
    display: inline-block;
    font-size: 14px;
    color: white;
  }
`;

const IndexPage = ({ location }) => {
  const title = "Emerald Cloud Lab: Remote Controlled Life Sciences Lab";

  //----------------------------------------------------------------------------------
  // TODO
  //
  // The initial screenWidth is set to desktop mode, so that when the page is fully
  // loaded on mobile, a re-render will be triggered and responsiveness works.
  //
  // Note that the {isOverBlah && (<></>)} followed by {!isOverBlah && (<></>)} are
  // intentional syntaxes to make sure responsiveness works. Using ternary clause
  // doesn't seem to work in the case of screen width changes.
  //
  // However, the current implementation causes the layout flickering (from desktop
  // to mobile) on mobile, because the markup defaults to desktop layout.
  //
  // Using libs like react-device-detect or react-responsive to set a different init
  // screenWidth for mobile doesn't really solve the issue. The fix would be using
  // https://github.com/artsy/fresnel?tab=readme-ov-file, and can be a bit more involved.
  //
  //----------------------------------------------------------------------------------
  const [screenWidth, setScreenWidth] = useState(1025);
  // Initial breakpoint for trusted by logos
  const isOver1024 = screenWidth > 1024;
  // Hexes go to single at 959
  const isOver959 = screenWidth > 959;
  // Single column text at 767
  const isOver767 = screenWidth > 767;
  const isUnder459 = screenWidth < 459;

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sectionThreeRef = useRef(null);
  const sectionSixRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove("hide");
            entry.target.classList.add("show");
          } else {
            entry.target.classList.remove("show");
            entry.target.classList.add("hide");
          }
        });
      },
      { threshold: 0.2 }
    );

    const observeSections = [sectionThreeRef.current, sectionSixRef.current];
    observeSections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observeSections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <Layout clearNav noMarginTop location={location}>
      <SEO image={pageImage} title={title} uri={location.pathname} />
      <SectionOne
        title={title}
        isOver1024={isOver1024}
        isUnder459={isUnder459}
      />
      <SectionTwoGreen isOver767={isOver767} isOver959={isOver959} />
      <S.SectionBackgroundImage className="ecl-lab1" />
      <SectionThreeWhite
        isOver767={isOver767}
        sectionThreeRef={sectionThreeRef}
      />
      <SectionFourCarousel />
      <S.SectionBackgroundImage className="ecl-lab2" />
      <SectionFiveGreen isOver767={isOver767} isOver959={isOver959} />
      <S.SectionBackgroundImage className="ecl-lab3" />
      <SectionSixWhite isUnder459={isUnder459} sectionSixRef={sectionSixRef} />
      <SectionSevenEnd isOver767={isOver767} />
    </Layout>
  );
};

S.SectionBackgroundImage = styled.div`
  overflow: hidden;
  position: relative;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background 3s ease-in;
  background-color: white;
  &.ecl-lab1 {
    z-index: 10;
    margin-top: -60px;
    padding-top: 200px;
    padding-bottom: 200px;
    height: 560px;
    width: 100%;
    background-image: url(${bgECLLAB1});
  }
  &.ecl-lab2 {
    z-index: 3;
    height: 600px;
    width: 100%;
    background-image: url(${bgECLLAB2});
  }
  &.ecl-lab3 {
    z-index: 3;
    margin-top: -18px;
    height: 600px;
    padding-top: 0;
    margin-top: 0;
    background-image: url(${bgECLLAB3});
  }
  @media only screen and (min-device-width: 200px) and (max-device-width: 1024px) {
    background-attachment: scroll !important;
    background-size: cover;
  }
`;

export default IndexPage;

// Uncomment this when the testimonial is back
//
// export const Head = () => (
//   <>
//     <script
//       defer
//       src="https://code.createjs.com/1.0.0/createjs.min.js"
//     ></script>
//   </>
// );
